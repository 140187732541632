jQuery(function($) {
    if ($('.single-product .dl_slider_galery').length > 0){
        window.addEventListener('load', e=>{
            console.log('funciona slider');
            $('.dl_slider_galery').slick({
                dots: false,
                infinite: true,
                speed: 300,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: false,
                autoplaySpeed: 3000,
                asNavFor: '.slider-nav'
            });
            $('.slider-nav').slick({
                slidesToShow: 3,
                slidesToScroll: 1,
                asNavFor: '.dl_slider_galery',
                dots: false,
                centerMode: true,
                focusOnSelect: true
            });
            let btnGalery = document.querySelector('#tab-title-gallery_tab a');
            btnGalery.addEventListener('click', e=>{
                setTimeout(function(){
                    let firstElementNav = document.querySelectorAll('.slider-nav .slick-slide')[0];
                    $(firstElementNav).click();
                    console.log(firstElementNav);
                    console.log('click');
                }, 100)
            })
        });
    }
});