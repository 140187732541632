jQuery(window).load(function() {
    jQuery(function($) {

        let stringDate = "";
        let arrayDates = [];

        // Procesar fechas_bloqueadas y agregar al arrayDates
        if (fechas_bloqueadas.length > 0) {
            for (let i = 0; i < fechas_bloqueadas.length; i++) {
                if (fechas_bloqueadas[i] === ",") {
                    arrayDates.push(stringDate);
                    stringDate = "";
                } else {
                    stringDate += fechas_bloqueadas[i]; 
                }
            }
            arrayDates.push(stringDate); // Agregar la última fecha procesada
        }
        
        if (typeof fechas_bloqueadas2 !== "undefined" && Array.isArray(fechas_bloqueadas2) && fechas_bloqueadas2.length > 0) {
            arrayDates = arrayDates.concat(fechas_bloqueadas2);
        }

        console.log("Array actualizado:", arrayDates);

        // Inicializar el datepicker
        $("#date-datepicker").datepicker({
            beforeShowDay: function(date) {
                var string = jQuery.datepicker.formatDate('yy-mm-dd', date);
                console.log(string + "\n");
                return [arrayDates.indexOf(string) === -1];
            },
            minDate: 0
        });

        console.log("test");
    });
});
