jQuery(window).load(function(){
    jQuery(function($) {
        if( $('.single-product').length > 0 ){
            console.log("test");
            let inputDropdown = document.querySelectorAll(".wc-pao-addon-field option")
            console.log(inputDropdown);
            let string = /[+$]/;
            inputDropdown.forEach(e => {
                console.log(e.childNodes[0].nodeValue);
                let result = string.test(e.childNodes[0].nodeValue);
                let texto = e.childNodes[0].nodeValue;

                if(result == true){
                    console.log("funciona");
                    let espacio = texto.indexOf('(');
                    console.log(espacio);
                    let txt = "";
                    for (let i = 0; i < espacio; i++) {
                        txt = txt + texto[i];
                    }
                    console.log(txt);
                    e.childNodes[0].nodeValue = txt;
                    console.log(e.childNodes[0].nodeValue);
                }else{
                    e.childNodes[0].nodeValue = texto;
                    console.log("nada");
                }
            });
            if( $('.woocommerce-message').length > 0 ){
                console.log("woo");
                let wooMessage = document.querySelector(".woocommerce-message a");
                console.log(wooMessage.childNodes[0].nodeValue);
                if(wooMessage.childNodes[0].nodeValue == "Continue shopping"){
                    window.location.replace("/checkout/");
                }else if (wooMessage.childNodes[0].nodeValue == "Seguir comprando") {
                    window.location.replace("/confirmar/");
                }

            }
        }
    });
});