jQuery(window).load(function(){
    //si esta en la clase checkout
    let mainBody = document.querySelector("body").attributes[0].nodeValue;
    let theClass = /single-product/;
    let result = theClass.test(mainBody);
    if(result == true){
        if( jQuery('.tour_meals_list').length > 0 ) {
            let contenedores = document.querySelectorAll(".taxonomy_container");
            contenedores.forEach((e)=>{
                console.log(e.childNodes[1]);
                if(e.childNodes[1] == undefined){
                    console.log(e.childNodes);
                    e.childNodes[0].innerHTML = "";
                }

            })
        }else{
            console.log(mainBody);
        }
    }
    console.log("Test");
});
console.log("Test");